import getConfig from 'next/config';
import { axiosErrorHandler, axiosWithAuth } from '../../utils';

const { publicRuntimeConfig } = getConfig();

const addDemoAsset: any = async (assetPayload: any) => {
  try {
    const reqPath = `${publicRuntimeConfig.SERVER_URL}/cache/data?x-evl-vertical=beverage`;
    const assetsResponse: any = await axiosWithAuth.post(reqPath, assetPayload, {});
    return assetsResponse;
  } catch (err) {
    const error = axiosErrorHandler(err);
    throw error;
  }
};

export default addDemoAsset;
