import * as Handlebars from 'handlebars';

import _size from 'lodash/size';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

export function parseById(id: string | number = 1, template: string = '') {
  const ptrn = new RegExp(`(\\[\\[${id}).+?(?=\\]\\])`, 'g');

  const openBracket = new RegExp(`\\[\\[${id}`, 'g');
  const closeBracket = new RegExp(`\\]\\]`, 'g');

  template?.match(ptrn)?.forEach(m => {
    const start = template.indexOf(m);
    const length = _size(m) + 2;

    template = template.replace(
      template.substr(start, length),
      template
        .substr(start, length)
        .replace(openBracket, '{{')
        .replace(closeBracket, '}}'),
    );
  });
  return template;
}

function provenanceToEventList(p: any, r: Array<any> = []): Array<any | undefined> {
  if (p.event.kind !== 'transaction') {
    r.push(p.event);
  }
  return _reduce(
    p.parents,
    (result: any[] | undefined, childProvenance: any) => {
      return provenanceToEventList(childProvenance, result);
    },
    r,
  );
}

Handlebars.registerHelper({
  eq: (v1, v2) => v1 === v2,
  ne: (v1, v2) => v1 !== v2,
  lt: (v1, v2) => v1 < v2,
  gt: (v1, v2) => v1 > v2,
  lte: (v1, v2) => v1 <= v2,
  gte: (v1, v2) => v1 >= v2,
  and() {
    return Array.prototype.every.call(arguments, Boolean);
  },
  or() {
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean);
  },
});

Handlebars.registerHelper('jsonString', function() {
  const args = Array.prototype.slice.call(arguments, 0, -1);
  return JSON.stringify(args[0]);
});

Handlebars.registerHelper('toProvovenanceList', function() {
  let provenanceData = Array.prototype.slice.call(arguments, 0, -1);
  provenanceData = provenanceData && provenanceData[0];
  return JSON.stringify(provenanceToEventList(provenanceData));
});

Handlebars.registerHelper('find', function(arr: any[], field: string, value: string) {
  return arr.find(x => x[field] === value);
});

Handlebars.registerHelper('get', function(obj: Object, item: string) {
  return _get(obj, item);
});

Handlebars.registerHelper('index', function(arr: any[], index: number) {
  return arr[index];
});

Handlebars.registerHelper('parseJson', function(json: string) {
  return JSON.parse(json);
});

Handlebars.registerHelper('convertISODate', function(
  date: string,
  long: boolean,
  ukFormat: boolean,
  separator?: string,
) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const newDate = date.slice(0, 10).split('-');

  if (long) {
    let suffix = 'th';

    switch (newDate[2].slice(-1)) {
      case '1':
        suffix = 'st';
        break;
      case '2':
        suffix = 'nd';
    }

    if (Number(newDate[2]) > 2) {
      suffix = 'th';
    }

    if (ukFormat) {
      return `${Number(newDate[2])}${suffix} ${months[Number(newDate[1]) - 1]} ${newDate[0]}`;
    } else {
      return `${months[Number(newDate[1]) - 1]} ${newDate[2]}${suffix} ${newDate[0]}`;
    }
  }

  if (ukFormat) {
    return newDate[2] + separator + newDate[1] + separator + newDate[0];
  } else {
    return newDate[1] + separator + newDate[2] + separator + newDate[0];
  }
});

Handlebars.registerHelper('trim', function(text: string) {
  return text?.replace(/\s+/g, '');
});

Handlebars.registerHelper('split', function(text: string, splitter: string) {
  return text.split(splitter);
});

Handlebars.registerHelper('slice', function(text: string | any[], from: number, to: number) {
  if (isNaN(to)) {
    return text.slice(from);
  }
  return text.slice(from, to);
});

export default Handlebars;
